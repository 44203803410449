











































import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default Vue.extend({
  name: "Carousel",
  components: {
    VueSlickCarousel,
  },
});
