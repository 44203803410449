























import Vue from "vue";
import ImageCard, { Card } from "@/components/layout/ImageCard.vue";

export default Vue.extend({
  name: "ImageCards",
  components: {
    ImageCard,
  },
  props: {
    cards: {
      type: Array as () => Card[],
      required: true,
    },
  },
});
