





















































import Post from "@/models/post";
import Vue from "vue";

export interface Card {
  title: string;
  search: Array<string>;
  img: string;
  post: Post | undefined;
}

export default Vue.extend({
  name: "ImageCard.Vue",
  props: {
    card: {
      type: Object as () => Card,
      required: true,
    },
  },
  data: function () {
    return {
      postLoaded: false,
    };
  },
  methods: {
    makePostShort(post: string): string {
      if (post.length > 140) {
        post = post.replace(/<\/?[^>]+(>|$)/g, "");
        return post.substring(0, 140) + "...";
      }
      return post;
    },
    removeHtmlTags(post: string): string {
      post = post.replace(/<\/?[^>]+(>|$)/g, "");
      post = post.replace("Adresse", "");
      post = post.replace("PLZ:", "");
      post = post.replace("Straße:", "");
      post = post.replace("Ort:", "");
      post = post.replace("Internet:", "");
      return post;
    },
  },
  computed: {
    query(): string {
      if (this.card.search.length < 1) {
        return "";
      }
      if (this.card.search.length === 1) {
        return this.card.search[0];
      }
      return this.card.search.reduce(
        (previousValue, currentValue) => `${previousValue},${currentValue}`
      );
    },
  },
});
