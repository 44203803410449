
















































import Vue from "vue";
import MenuButton from "@/components/layout/MenuButton.vue";
import PostService from "@/services/PostService";

export default Vue.extend({
  name: "MainHeader",
  components: {
    MenuButton,
  },
  data: function () {
    return {
      nationalCount: 0,
      nationalImg: require(`@/assets/images/area/${this.$i18n.locale}-Flag.png`),
      internationalCount: 0,
      internationalImg: require("@/assets/images/area/240px-Earth_icon_2.png"),
    };
  },
  async mounted() {
    // load internationalCount
    this.internationalCount = await PostService.countPosts(true);
    this.nationalCount = await PostService.countPosts(false);
  },
});
